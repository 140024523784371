<template>
<div class=" h-full  w-full ">
    <div class="bg-white w-full sm:h-14 lg:h-24 shadow-md flex justify-center">
          <div class="sm:h-14 lg:h-20 sm:w-48 lg:w-48 sm:mt-0 lg:mt-2">
            <div @click="$router.push('/')" class="h-full w-auto bg-contain bg-center cursor-pointer" :style="`background-image: url('`+logoOrrico+`')`"></div>
          </div>
    </div>
    <div class="rounded-md sm:mr-5 sm:ml-5 lg:mr-24 lg:ml-24 sm:mt-5 lg:mt-17 h-auto">
        <div class="pt-4">
            <h1 class="text-center text-2xl text-primary-700 font-medium"> Aviso de Privacidad para el Sistema Dental <span class="underline">"Dental Orrico"</span></h1>
        </div>

        <div class="pt-5 m-7 pb-5">
     
            <p class="mb-4 font-serif">En cumplimiento de la legislación vigente en materia de protección de datos personales, en especial de la LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE LOS PARTICULARES Con fundamento en los artículos 15 y 16, se emite el siguiente Aviso de Privacidad para el sistema dental <a href="https://www.dentalorrico.net" class="text-blue-500 underline">www.dentalorrico.net</a>:</p>

            <div class="shadow-sm mb-4">
                <h2 class=" p-5 text-xl font-semibold mt-6 mb-2">1. Responsable del tratamiento de datos personales:</h2>
                <p class="pb-5 pl-5 pr-5 font-serif">Ángeles Orrico Velázquez, Dentista (en adelante, "nosotros" o "el responsable") es el responsable del tratamiento de los datos personales recabados a través del sistema dental.</p>
            </div>

            <div class="shadow-sm mb-4">
                <h2 class="text-xl font-semibold mt-6 mb-2 p-5">2. Datos personales recabados:</h2>
                <div class="flex pb-4">
                    <div class="w-full  mr-2 ml-4 rounded-md">
                        <span class="text-center ml-4">Información de identificación:</span>
                        <ul class="list-disc list-inside pl-5 space-y-2 text-gray-700 sm:text-sm">
                            <li>
                                Nombre completo
                            </li>
                            <li>
                                Fecha de nacimiento
                            </li>
                            <li>
                                RFC
                            </li>
                            <li>
                                Ocupacion
                            </li>
                            <li>
                                Dirección
                            </li>
                            <li>
                                Número de teléfono
                            </li>
                            <li>
                                Dirección de correo electrónico
                            </li>
                            <li>
                                Foto del rostro
                            </li>
                            <li>
                                Firma autógrafa
                            </li>
                        </ul>
                    </div>
                    <div class="w-full mr-2 rounded-md">   
                        <span class="text-center ml-4">Datos clínicos:</span>
                        <ul class="list-disc list-inside pl-5 space-y-2 text-gray-700 sm:text-sm">
                            <li>
                                Historial médico
                            </li>
                            <li>
                                Antecedentes dentales
                            </li>
                            <li>
                                Diagnósticos
                            </li>
                            <li>
                                Tratamientos realizados
                            </li>
                            <li>
                                Radiografías
                            </li>
                            <li>
                                Cualquier otro dato relevante para la atención dental
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="shadow-sm p-5 mb-4">
                <h2 class="text-xl font-semibold mt-6 mb-2">3. Finalidad del tratamiento de datos personales:</h2>
                <p class="mb-4">La recopilación y tratamiento de los datos personales tiene como finalidad principal la prestación de servicios odontológicos y la atención de los pacientes. Los datos personales se utilizan para los siguientes propósitos:</p>
                <ul class="list-disc list-inside mb-4">
                    <li>
                                Registro y creación de expedientes clínicos de los pacientes.
                    </li>
                    <li>
                                Comunicación con los pacientes para recordatorios de citas, seguimiento de tratamientos e información relevante sobre su salud dental.
                    </li>
                    <li>
                                Facturación y gestión de pagos.
                    </li>
                    <li>
                               Cumplimiento de obligaciones legales y regulatorias.
                    </li>
                </ul>
            </div>

            <div class="shadow-sm p-5 ">
  <h2 class="text-xl font-semibold mt-6 mb-2">4. Confidencialidad y Seguridad de los Datos</h2>

  <p class="text-gray-700 mb-4">
    Nos comprometemos a proteger la confidencialidad y seguridad de los datos personales recabados a través del sistema dental. Implementamos medidas técnicas, administrativas y físicas adecuadas para salvaguardar la información y prevenir su acceso no autorizado, uso o divulgación.
  </p>

  <div class="space-y-6">
    <!-- Medidas de Seguridad -->
    <div class="space-y-2">
      <h3 class="text-xl font-semibold text-gray-800">Medidas de Seguridad:</h3>
      <ul class="list-disc list-inside pl-6 space-y-2 text-gray-700">
        <li><strong>Cifrado de Datos:</strong> Todos los datos personales transmitidos a través del sistema están protegidos mediante protocolos de cifrado avanzados (por ejemplo, SSL/TLS) para garantizar que la información esté segura durante su transferencia.</li>
        <li><strong>Autenticación y Control de Accesos:</strong> Se implementan mecanismos robustos de autenticación y control de accesos para garantizar que solo el personal autorizado pueda acceder a los datos personales. Esto incluye autenticación multifactor (MFA) y contraseñas fuertes.</li>
        <li><strong>Auditoría y Monitoreo Continuo:</strong> Realizamos auditorías periódicas y monitoreo constante del sistema para detectar y prevenir accesos no autorizados, alteraciones o vulnerabilidades en la infraestructura de datos.</li>
        <li><strong>Protección Física de la Información:</strong> Los datos almacenados en nuestros servidores están protegidos en instalaciones con acceso restringido y bajo un control físico adecuado, asegurando que los datos no sean vulnerables a robos o daños físicos.</li>
      </ul>
    </div>


        <!-- Protección contra Pérdida o Robo de Datos -->
        <div class="space-y-2">
        <h3 class="text-xl font-semibold text-gray-800">Protección contra Pérdida o Robo de Datos:</h3>
        <p class="text-gray-700">
            En el caso de que se detecte una violación de seguridad que comprometa los datos personales de nuestros usuarios, nos comprometemos a notificar de manera inmediata a los usuarios afectados, conforme a las leyes aplicables. En caso de que se trate de una violación significativa de datos, se llevará a cabo una investigación exhaustiva para determinar el alcance de los daños y se tomarán las acciones correctivas necesarias.
        </p>
        </div>

    </div>
    </div>
            <h2 class="text-xl font-semibold mt-6 mb-2">5. Compartir datos personales:</h2>
            <p class="mb-4 font-serif">En algunos casos, podemos compartir los datos personales recopilados con terceros, siempre y cuando sea necesario para cumplir con los fines descritos en este aviso de privacidad. Estos terceros pueden incluir proveedores de servicios tecnológicos, personal médico y autoridades competentes en cumplimiento de la ley.</p>
        
            <h2 class="text-xl font-semibold mt-6 mb-2">6. Derechos de los titulares de los datos:</h2>
            <p class="mb-4 font-serif">Los titulares de los datos personales tienen derecho a acceder, rectificar, cancelar u oponerse al tratamiento de sus datos personales. Para ejercer estos derechos, los pacientes pueden ponerse en contacto con nosotros a través de los canales de comunicación proporcionados al final de este aviso.</p>
        
            <h2 class="text-xl font-semibold mt-6 mb-2">7. Cambios al aviso de privacidad:</h2>
            <p class="mb-4 font-serif"> Nos reservamos el derecho de actualizar o modificar este aviso de privacidad en cualquier momento para reflejar cambios en nuestras prácticas de tratamiento de datos. Cualquier modificación será publicada en el sistema dental y se informará a los pacientes de manera oportuna.</p>

            <h2 class="text-xl font-semibold mt-6 mb-2">8. Contacto:</h2>
            <p class="mb-4 font-serif"> Para cualquier consulta, solicitud o ejercicio de derechos relacionados con el tratamiento de datos personales, los pacientes pueden ponerse en contacto con nosotros a través de los siguientes medios:</p>
            <ul class="list-disc list-inside mb-4">
                <li class="font-serif">Teléfono: <span class="font-sans">961 254 3896</span></li>
                <li class="font-serif"> Correo: <span class="font-sans">a.orrico.v@icloud.com</span></li>
            </ul>

             <p class="mb-4 text-xl font-sans"> Te recordamos que es importante leer y comprender este aviso de privacidad antes de proporcionar cualquier dato personal a través del sistema dental. Al utilizar nuestros servicios, los pacientes aceptan los términos y condiciones descritos en este aviso de privacidad.</p>
        </div>
    </div>

</div>
</template>

<script>
export default {
    data: function() {
        return {
            logoOrrico: require('@/assets/login/doLogotypeSmall.png')
        }
    },


}
</script>

<style lang="scss" scoped>

</style>